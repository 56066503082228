import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
// import AliceCarousel from 'react-alice-carousel';

// import "react-alice-carousel/lib/scss/alice-carousel.scss";

const CustomSlider = ({settings, children}) => {
  return (
    <Slider {...settings}>
      {children}
    </Slider>
  )
}

export default CustomSlider