
import arrow from '../../image/icons/arrow-white.webp';
import giftOrange from '../../image/icons/gift_orange.webp';

import './Reasons.scss';

const data = [
  {
    text: 'Працюємо тільки з екологічно чистими фарбами, які забезпечують',
    bolt: 'кольорову передачу 1 в 1.',
    src: '',
  },
  {
    text: 'Тільки японські принтери',
    bolt: 'EPSON.',
    src: '',
  },
  {
    text: 'Виготовлення',
    bolt: '3 години.',
    src: '',
  },
  {
    text: 'До кожного замовлення йде фотомагніт',
    bolt: 'у подарунок.',
    src: giftOrange,
  }
]

const Reasons = () => {

  const renderReason = (data) => {
    return data.map((item, i) => {
      const {text, bolt, src} = item;
      const renderImg = src !== '' ? <img className='reasons__gift' src={src} alt='gift' /> : null;

      return (
        <li className='reasons__item' key={i}>
          <span className="reasons__num">{i + 1}</span>
          <div className="reasons__text">
            <p>
              {text} <span>{bolt}</span>
            </p>
            
            {renderImg}
          </div>
        </li>
      )
    })
  }

  return (
    <section className='reasons reveal'>
      <div className="reasons__container">
        <div className="reasons__header">
          <h2 className='reasons__header-title'>4 причини <span>купити у нас</span></h2>
          <img className='reasons__header-icon' src={arrow} alt="arrow icon" />
        </div>

        <ul className="reasons__list">
          {renderReason(data)}
        </ul>
      </div>
    </section>
  )

} 

export default Reasons;