
import price from '../../image/price.webp';

import './Size.scss';

const Size = () => {
  return (
    <>
    <a id="size"></a>
    <div className="tariffs reveal">
      <h2 className='tariffs__title size__title'>Доступні розміри для замовлення</h2>
      <img src={price} alt="price" className='size__img' />
    </div>
    </>
    
  )
}

export default Size;