import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import './Faq.scss';


const data = [
  {
    question: 'Як буде виглядати моя картина?',
    answer: 'Після отримання фото, наші дизайнери підготують макет(оброблене та покращене фото), з його допомогою ви побачите, як картина буде виглядати на полотні.'
  },
  {
    question: 'Чи підійде якість мого фото?',
    answer: 'Більшість фотографій підходить, зробимо картину навість зі скріншоту. '
  },
  {
    question: 'Які терміни?',
    answer: 'Виготовлення від 3-х годин, при замовленні вкажіть дату на яку Вам потрібна картина.'
  },
  {
    question: 'Як обрати розмір?',
    answer: 'Для різного співвідношення сторін фото, підходять різні розміри картин. Наш менеджер обов‘язково допоможе вам з вибором.'
  },
]

const Faq = () => {

  const customClassesAccordion = {
    root: 'faq__accordion',
    rounded: 'custom-rounded-class',
    expanded: 'custom-expanded-class',
    disabled: 'custom-disabled-class',
    region: 'custom-region-class',
  };

  const customClassesDetails = {
    root: 'faq__details',
    rounded: 'custom-rounded-class',
    expanded: 'custom-expanded-class',
    disabled: 'custom-disabled-class',
    region: 'custom-region-class',
  };

 

  const customClassesSummary = {
    root: 'faq__summary',
    rounded: 'custom-rounded-class',
    expanded: 'custom-expanded-class',
    disabled: 'custom-disabled-class',
    region: 'custom-region-class',
    
  }

  const renderAccordion = (data) => {
    return data.map((item, i) => {
      const {question, answer} = item;

      return (
          <Accordion  
            key={i}
            classes={customClassesAccordion}
            >
            <AccordionSummary
              classes={customClassesSummary}
              expandIcon={
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="24" viewBox="0 0 28 24" fill="none">
                  <path d="M13.9177 23.5059L0.337984 0.806154L27.4974 0.806156L13.9177 23.5059Z" fill="white"/>
                </svg>
              }            
              aria-controls="panel1a-content"
              id="panel1a-header"

            >
              {question}
            </AccordionSummary>
            <AccordionDetails
              classes={customClassesDetails}
              >
              {answer}
            </AccordionDetails>
          </Accordion>
      )
    })
  }

  return (
    <>
      <a id="faq"></a>
      <section className="faq reveal">
        
        <div className="faq__bg1"></div>
        <div className="faq__bg2"></div>

      <div className="faq__container">
          <h2 className="faq__title">Часті питання та відповіді</h2>

          <div className="faq__accourdings">
            {renderAccordion(data)}
          </div>
      </div>
      </section>
    </>
    
  )
}

export default Faq;