import AliceCarousel from 'react-alice-carousel';

import "react-alice-carousel/lib/scss/alice-carousel.scss";

const AlisaSlider = ({settings, children}) => {
  return (
    // <Slider {...settings}>
    //   {children}
    // </Slider>

    <AliceCarousel {...settings}>
      {children}
    </AliceCarousel>
  )
}

export default AlisaSlider