import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Faq from "../../components/Faq/Faq";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Main from "../../components/Main/Main";
import OurServices from "../../components/OurServices/OurServices";
import PriceIncluded from "../../components/PriceIncluded/PriceIncluded";
import Reasons from "../../components/Reasons/Reasons";
import Reviews from "../../components/Reviews/Reviews";
import StepOrder from "../../components/StepOrder/StepOrder";
import Tariffs from "../../components/Tariffs/Tariffs";
import UniqueProposition from "../../components/UniqueProposition/UniqueProposition";
import WorkExemple from "../../components/WorkExemple/WorkExemple";

import Modal from "../../components/Modal/Modal";

import bgLine from "../../image/backgroun-line.webp";
import Size from "../../components/Size/Size";
import Gallery from "../../components/Gallery/Gallery";

const MainPage = () => {
  const [modal, setModal] = useState(false);
  const [size, setSize] = useState("");

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [modal]);

  return (
    <div className="app">
      <Helmet>
        {/* Ваш код Meta Pixel */}
        <script>
          {`
            // Ваш код Facebook Pixel
            !function(f, b, e, v, n, t, s) {
              if (f.fbq) return;
              n = f.fbq = function() {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
              };
              if (!f._fbq) f._fbq = n;
              n.push = n;
              n.loaded = !0;
              n.version = '2.0';
              n.queue = [];
              t = b.createElement(e);
              t.async = !0;
              t.src = v;
              s = b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t, s);
            }(window, document, 'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1055364738832027');
            fbq('track', 'PageView');
          `}
        </script>

        <script type="text/javascript">
          {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "p960oogcd0");`}
        </script>
        {/* Кінець вашого коду Meta Pixel */}
      </Helmet>
      {modal ? <Modal close={setModal} setSize={setSize} size={size} /> : null}
      <img src={bgLine} alt="bg line" className="app__bgLine" />
      <div className="app__container">
        <div className="app__adv">1+1 = 3 Замов прямо зараз! </div>

        <Header modal={setModal} />
        <Main modal={setModal} />
        <StepOrder />
        <UniqueProposition />
        <OurServices modal={setModal} />
        <Tariffs modal={setModal} setSize={setSize} />
        <Size />
        <PriceIncluded />

        <Gallery />
        <Reasons />
        <WorkExemple />
        <Reviews modal={setModal} />

        <Faq />
        <Footer />
      </div>
    </div>
  );
};

export default MainPage;
