
import './OurServices.scss';
import picture1 from '../../image/our-services/1.webp';
import picture2 from '../../image/our-services/2.webp';
import picture3 from '../../image/our-services/3.webp';
import picture4 from '../../image/our-services/4.webp';
import picture5 from '../../image/our-services/5.webp';
import picture6 from '../../image/our-services/6.webp';

const data = [
  {
    src: picture1,
    button: 'Картина з годинником',
    text: '',
    classes: ''
  },
  {
    src: picture2,
    button: 'Колаж',
    text: '',
    classes: ''
  },
  {
    src: picture3,
    button: 'Додання кольору ЧБ фото',
    text: '',
    classes: ''
  },
  {
    src: picture4,
    button: 'Поєднання з декількох фото',
    text: 'Редактор вирізає людей та поєднує на одному фоні',
    classes: 'services__img-top20'
  },
  {
    src: picture5,
    button: 'Дрім-арт',
    text: 'Художня промальовка на графічному планшеті, основний фон змінюється на новий',
    classes: ''
  },
  {
    src: picture6,
    button: 'Заміна фону ',
    text: 'Ми індивідуально підбираємо 6-8 варіантів фону ,а ви обираєте той який сподобався',
    classes: ''
  },
]

const OurServices = ({modal}) => {

  const renderServices = (data) => {
    return data.map(item => {
      const {src, button, text, classes} = item;
      const renderText = text.length > 0 ? <p className='services__text'>{text}</p> : null;

      return (
        <li className='services__item' key={button}>
          <img src={src} className={`services__img ${classes}`} alt={button} />
          <button onClick={() => modal(true)} className='button services__description'>{button}</button>
          {renderText}
        </li>
      )
    })
  }

  return (
    <>
      <a id='services'></a>
      <section className='services reveal'>
        
        <div className="services__bg1"></div>
        <div className="services__bg2"></div>

      <div className="services__container">
        <h2 className="services__title">послуги</h2>

        <ul className="services__list">
          {renderServices(data)}
        </ul>
      </div>
      </section>
    </>
    
  )
}

export default OurServices;