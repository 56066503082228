import { useEffect, useState } from 'react'

import { Sling as Hamburger } from 'hamburger-react'

import './Header.scss'

const Header = ({ modal }) => {
  const [showMenu, setShowMenu] = useState(false)
  const [isFixed, setIsFixed] = useState(false)

  useEffect(() => {
    showMenu
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = '')
  }, [showMenu])

  const checkedMenu = () => {
    setShowMenu(false)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsFixed(true)
      } else {
        setIsFixed(false)
      }
    }

    // Додаємо слухач події прокрутки
    window.addEventListener('scroll', handleScroll)

    // Видаляємо слухач при демонтажі компонента
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <header className={`header desktop ${isFixed ? 'fixed' : ''}`}>
        <div className='header__container'>
          <nav className='header__nav nav'>
            <ul className='nav__list'>
              <li className='nav__item'>
                <a href='#services' className='nav__link'>
                  Послуги
                </a>
              </li>
              <li className='nav__item'>
                <a href='#price' className='nav__link'>
                  Ціни
                </a>
              </li>
              <li className='nav__item'>
                <a href='#size' className='nav__link'>
                  Розміри
                </a>
              </li>
              <li className='nav__item'>
                <a href='#faq' className='nav__link'>
                  Питання
                </a>
              </li>
            </ul>
          </nav>

          <ul className='header__order order__list'>
            <li className='order__item'>
              <a href='tel: +380957204767' className='order__link'>
                +38 (095) 720 4767
              </a>
            </li>
            <li className='order__item'>
              <a
                href='https://www.instagram.com/omnia_holst/?igsh=dXN4djl5NjE2NmMx'
                target='_blank'
                rel='noopener noreferrer'
                className='order__item-icon'
              ></a>
            </li>
            <li className='order__item'>
              <button
                className='order__button button f-size-16'
                onClick={() => modal(true)}
              >
                Замовити
              </button>
            </li>
          </ul>
        </div>
      </header>

      <header className={`header header__mobile ${isFixed ? 'fixed' : ''}`}>
        <ul className='header__order header__order-mobile order__list'>
          <li className='order__item'>
            <a
              href='https://www.instagram.com/omnia_holst/?igsh=dXN4djl5NjE2NmMx'
              target='_blank'
              rel='noopener noreferrer'
              className='order__item-icon'
            ></a>
          </li>
          <li className='order__item'>
            <a href='tel: +380957204767' className='order__link'>
              +38 (095) 720 4767
            </a>
          </li>
          <li className='order__item'>
            <Hamburger toggled={showMenu} toggle={setShowMenu} />
          </li>
        </ul>

        {showMenu ? (
          <nav className={`header__nav nav-mobile-menu  nav ${isFixed ? 'fixed-mobile' : ''}`}>
            <ul className='nav__list'>
              <li className='nav__item' onClick={checkedMenu}>
                <a href='#services' className='nav__link'>
                  Послуги
                </a>
              </li>
              <li className='nav__item' onClick={checkedMenu}>
                <a href='#price' className='nav__link'>
                  Ціни
                </a>
              </li>
              <li className='nav__item' onClick={checkedMenu}>
                <a href='#size' className='nav__link'>
                  Розміри
                </a>
              </li>
              <li className='nav__item' onClick={checkedMenu}>
                <a href='#faq' className='nav__link'>
                  Питання
                </a>
              </li>
            </ul>
          </nav>
        ) : null}
      </header>
    </>
  )
}

export default Header
