import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import SendFormService from '../../service/SendFormService';

import insta from '../../image/icons/insta.webp';
import facebook from '../../image/icons/facebook-orange.webp';
import viber from '../../image/icons/viber-orange.webp';
import telegram from '../../image/icons/telegram-orange.webp';
import phoneIcon from '../../image/icons/phone.webp';

import validationForm from '../../utils/validation';

import arrow from '../../image/icons/step_arrow.webp';



import './Footer.scss';


const Footer = () => {
  const [disabled, setDisabled] = useState(true)

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const {sendForm} = SendFormService();
  const navigate = useNavigate();

  useEffect (() => {
   if (!validationForm(name, 'name').errorStatus && name !== '' && phone !== '' && !validationForm(phone, 'number').errorStatus) {
      setDisabled(false);
      
    } else {
      setDisabled(true);
    }

  }, [name, phone])


  const formSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name, phone, 
    };

      sendForm(data)
      .then(() => navigate('/thanks'))
      .catch((e) => console.error(e))
      .finally(() => {
        setName('');
        setPhone('');

      })

  }

  return (
    <footer className='footer reveal'>
      <div className="footer__header">
        <h2 className='footer__title'>У Вас залишились питання? Не проблема! <br /> <span className='footer__title-orange'>Ми якнайшвидше </span> <span className='footer__title-orange footer__title-start'>зв’яжемося</span>  <span className='footer__title-black'>із Вами.</span></h2>
        <img src={arrow} alt="arrow" />
      </div>

      <div className="footer__container">
        <form className='footer__form' onSubmit={formSubmit}>
          <label htmlFor="footer__form-name" className='footer__label'>
            <input 
              type="text" 
              id='footer__form-name' 
              className='footer__input' 
              placeholder='Ім’я'
              value={name}
              onChange={(e) => setName(e.target.value)}/>
            {validationForm(name, 'name').errorStatus ? <div className="error">Мінімум 2 символи</div> : null}
          </label>
          <label htmlFor="footer__form-phone" className='footer__label'>
            <input 
              type="text" 
              id='footer__form-phone' 
              className='footer__input'  
              placeholder='+38 (***) *** ****'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}/>
            {validationForm(phone, 'number').errorStatus ? <div className="error ">Формат +380999999999</div> : null}
          </label>

          <button className='footer__submit button' disabled={disabled}>Відправити</button>
        </form>
      </div>

      <div className="footer__contacts">
        <div className="footer__contacts-div">
          <img src={phoneIcon} className='footer__contacts-phone' alt="phone" />
          <div className="footer__numbers">
            {/* <a href="tel: +380972756371">+38 (097) 2756 371</a>
            <a href="tel: +380980576660 ">+38 (098) 0576 660</a> */}
            <a href="tel: +380957204767 ">+38 (095) 7204 767</a>
          </div>
        </div>

        <div className="footer__social">
          <a href="https://www.instagram.com/omnia_holst/?igsh=dXN4djl5NjE2NmMx" target="_blank" className='footer__social-insta' rel="noopener noreferrer">
            <img src={insta} alt="insta" />
          </a>
          
          <a href="https://www.facebook.com/profile.php?id=100066356749009" target="_blank" className='footer__social-facebook' rel="noopener noreferrer">
            <img src={facebook} alt="facebook" /> 
          </a>
          
          <a href="viber://chat?number=%2B380957204767" target="_blank" className='footer__social-viber' rel="noopener noreferrer">
            <img src={viber} alt="viber" />
          </a>
          
          <a href="https://t.me/sofiatsiupa" target="_blank" className='footer__social-telegram' rel="noopener noreferrer">
            <img src={telegram} alt="telegram" />
          </a>
          
        </div>
      </div>

      <a href="https://newstart-digital.com.ua/" className='footer__developer' target="_blank" rel="noopener noreferrer">Created by New Start Digital</a>


    </footer>
  )
}

export default Footer;