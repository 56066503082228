import Spinner from "../Spiner"

const Loading = ({ label }) => {
  return (
    <div className='flex flex-col gap-4 w-full h-[75vh] justify-center items-center'>
      <Spinner/>
      <p>{label ?? 'Loading...'}</p>
    </div>
  )
}

export default Loading