
import { useEffect, useState } from 'react';
import openArrow from '../../image/icons/open-arrow.webp';



import './Tariffs.scss';

const data = [
  {
    size: '20x30',
    fakePrice: '355',
    realPrice: '280',
  },
  {
    size: '25x35',
    fakePrice: '368',
    realPrice: '290',
  },
  {
    size: '30x30',
    fakePrice: '444',
    realPrice: '350',
  },
  {
    size: '30x40',
    fakePrice: '520',
    realPrice: '410',
  },
  {
    size: '30x45',
    fakePrice: '533',
    realPrice: '420',
  },
  {
    size: '30x50',
    fakePrice: '584',
    realPrice: '460',
  },
  {
    size: '35x45',
    fakePrice: '584',
    realPrice: '460',
  },
  {
    size: '40x40',
    fakePrice: '584',
    realPrice: '460',
  },
  {
    size: '40x50',
    fakePrice: '622',
    realPrice: '490',
  },
  {
    size: '40x60',
    fakePrice: '685',
    realPrice: '540',
  },
  {
    size: '50x50',
    fakePrice: '685',
    realPrice: '540',
  },
  {
    size: '50x70',
    fakePrice: '825',
    realPrice: '650',
  },
  {
    size: '70x70',
    fakePrice: '1003',
    realPrice: '790',
  },
  {
    size: '60x90',
    fakePrice: '1041',
    realPrice: '820',
  },
  {
    size: '70x100',
    fakePrice: '1193',
    realPrice: '940',
  },
  {
    size: '90x120',
    fakePrice: '1524',
    realPrice: '1200',
  },

]

const Tariffs = ({modal, setSize}) => {

  const [openList, setOpenList] = useState(false);
  // const [heightList, setHeightList] = useState('710px')

  const renderTariffs = (data) => {
    return data.map((item, i) => {
      const {size, fakePrice, realPrice} = item;

      return (
        <li className="tariffs__list-item" key={size}>
          <p className="tariffs__list-item-size">Розмір {size} см</p>
          <div className="tariffs__list-item-prices">
            Ціна
            <div className="tariffs__list-item-prices-container">
              <span className='tariffs__list-item-prices-fake'>{fakePrice}</span>
              <span className='tariffs__list-item-prices-real'>{realPrice}</span>
            </div>
            
          </div>
          <div className="tariffs__list-item-buttons">
            <button 
              className="tariffs__list-item-button button" 
              onClick={(e) => 
                {
                  modal(true)
                  setSize(size)
                }
              }
               data-size={size}>Замовити</button>
          </div>
          
        </li>
      )
    })
  }

  const changeListStyle = () => {
    
    if (openList) {
      return {
        maxHeight: '2550px',
        transition: 'all .5s'
      }
    } else {
      return {
        maxHeight: window.screen.width > 600 ? '710px' : '530px',
        transition: 'all .5s'
      }
    }
  }

  return (
    <>
      <a id="price"></a>
      <section className='tariffs reveal'>

        
        <h2 className='tariffs__title'>Ціни</h2>

        <div className="tariffs__container">
          <ul className="tariffs__list" style={changeListStyle()}>
            {renderTariffs(data)}
          </ul>


          <button className='tariffs__open' onClick={() => setOpenList(!openList)}>
            {openList ? 'ЗМЕНШИТИ' : 'РОЗГОРНУТИ'}
            <img src={openArrow} alt="open arrow" style={openList ? {transform: 'rotate(180deg)'}: {}}/>
          </button>

          <button className='button tariffs__button' onClick={() => modal(true)}> Допомогти Вам з вибором розміру?</button>

        </div>

        
      </section>
    </>
  )
}

export default Tariffs;