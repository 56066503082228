
import stepsArrow from '../../image/icons/step_arrow.webp';

import pictureIcon from '../../image/icons/picture-icon.webp';
import handsIcon from '../../image/icons/hangs-icon.webp';
import timeIcon from '../../image/icons/time-icon.webp';
import deliveryIcon from '../../image/icons/delivery-icon.webp';


import './StepOrder.scss';

const data = [
  {
    src: pictureIcon,
    title: 'Відправте нам фото',
    text: ''
  },
  {
    src: handsIcon,
    title: 'Узгодьте отриманий макет',
    text: '(оброблене та покращене фото)'
  },
  {
    src: timeIcon,
    title: 'Виготовлення від 3-х годин',
    text: ''
  },
  {
    src: deliveryIcon,
    title: 'Доставка 1-2 дні',
    text: ''
  }
]

const StepOrder = () => {

  const renderItem = (data) => {
    return data.map((item, i) => {
      const {src, title, text} = item;
      const textInfo = text.length > 0 ? <p className='steps__list-text'>{text}</p> : null;

      return (
        <li className="steps__list-item" key={i}>
          <span className="steps__list-decour">
            {i + 1}
            <img src={src} className='steps__list-img' alt={title} /> 
          </span>
          <h3 className="steps__list-title">{title}</h3>
          {textInfo}
        </li>
      )
    })
  }

  return (
    <section className='steps reveal'>
      <div className="steps__header">
        <h2 className='steps__header-title'>Кроки оформлення замовлення</h2>
        <img src={stepsArrow} alt="steps arrow" className='steps__header-icon' />
      </div>
      <ul className="steps__list">
        {renderItem(data)}
     
      </ul>

    </section>
  )
}

export default StepOrder