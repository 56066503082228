import { useNavigate } from 'react-router-dom';

import { useEffect, useState } from 'react';

import validationForm from '../../utils/validation';

import SendFormService from '../../service/SendFormService';

import photo from '../../image/icons/photo.webp'; 

import './Modal.scss';


const Modal = ({close, size, setSize}) => {
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [photos, setPhoto] = useState(null);

  const navigate = useNavigate();

  const {sendForm, sendFile} = SendFormService();

  useEffect(() => {
    if (!validationForm(name, 'name').errorStatus && name !== '' && phone !== '' && !validationForm(phone, 'number').errorStatus) {
      setDisabled(false);
      
    } else {
      setDisabled(true);
    }

  }, [name, phone])

  useEffect(() => {
    console.log(name);
  }, [name])

  const formSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name, phone, message, size
    };

    if (photos !== null) {
      sendForm(data)
      .then(() => sendFile(photos))
      .then(() => navigate('/thanks'))
      .catch((e) => console.error(e))
      .finally(() => {
        setName('');
        setPhone('');
        setMessage('');
        setPhoto('');
        setSize('');
      })
    } else {
      sendForm(data)
      .then(() => navigate('/thanks'))
      .catch((e) => console.error(e))
      .finally(() => {
        setName('');
        setPhone('');
        setMessage('');
        setPhoto('');

        close(false);
      })
    }

    

  }

  return (
    <div className='modal'>
      <div className="modal__container">
        <span className='modal__close' onClick={() => close(false)}>x</span>

        <div className='modal__text'>
          <h3>Залиште ваші контактні дані,</h3>
           наш менеджер зв’яжеться та запропонує варіанти по оформленню ідеального подарунка
        </div>
        <form className='modal__form modal-form' onSubmit={formSubmit}>
          <label htmlFor="name" className='modal-form__label'>
            <input 
              type="text" 
              id='name' 
              className='modal-form__input' 
              name='name'
              placeholder='Ім’я'
              value={name}
              onChange={(e) => setName(e.target.value)}/>
            {validationForm(name, 'name').errorStatus ? <div className="error">Мінімум 2 символи</div> : null}
          </label>
          <label htmlFor="phone" className='modal-form__label'>
            <input 
              type="text" 
              id='phone' 
              name='phone'
              className='modal-form__input' 
              placeholder='Номер телефону'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}/>
            {validationForm(phone, 'number').errorStatus ? <div className="error ">Формат +380999999999</div> : null}
          </label>

          <textarea 
            className='modal-form__textarea' 
            name="message" 
            id="" 
            placeholder='Коментар'
            value={message}
            onChange={e => setMessage(e.target.value)}></textarea>


          <div className="modal-form__send">
            <div>
              <label
                htmlFor="avatar-change"
                className="modal-form__changed-avatar button"
              >
                <img src={photo} alt="photo" />
                
                <input 
                  className="modal-form__input" 
                  type="file" 
                  id="avatar-change" 
                  name='photo'
                  // value={photos}
                  onChange={e => {setPhoto(e.target.files[0]) 
                    console.log(e)}}
                  />
              </label>
              <span className='modal-form__send-text'>Вставити зображення</span>
            </div>
            
            <button className='modal-form__submit button button-disabled' disabled={disabled}>Відправити</button>
          </div>

          
        </form>
      </div>
    </div>
  )
}

export default Modal;