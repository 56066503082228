
import AlisaSlider from '../Slider/AlisaSlider';
import YouTube from 'react-youtube';

import './WorkExemple.scss';
import { useEffect } from 'react';

const WorkExemple = () => {
  let slideToShow = 1;

  useEffect(() => {
    if (window.screen.width < 1130) {
      slideToShow = 2;
    } else if (window.screen.width < 700) {
      slideToShow = 1;
    }
  }, [window.screen.width]);

  const settingSlider = {
    autoPlay: true,
    speed: 10000,
    infinite: true,
    autoPlayInterval: 5000,
    responsive: {
      0: {
        items: 1,
      },
      850: {
        items: 2,
        itemsFit: 'contain',
      },
      1150: {
        items: 2,
      },
    },
  };

  const videoIds = [
    '34Zogd-ctSw',
    'dZB1cBqbYug',
    'GIjZBy60pOo',
    'vyuZogYROmI',
    'jgdzj8DLHVo',
    'pQo8GbLy_Ec',
    'V7F0p5SMUKA',
    '2GPW4juBZU0',
    'jXlmQaAj0tM',
  ];

  const videoOptions = {
    height: '315',
    width: '100%',
    playerVars: {
      autoplay: 0,
      rel: 0, 
      modestbranding: 1, 
    },
  };

  return (
    <section className="example reveal">
      <h2 className="example__title">Відео відгуки</h2>

      <div className="example__slider">
        <AlisaSlider settings={settingSlider}>
          {videoIds.map((id, index) => (
            <div key={index} className="video-slide">
              <YouTube videoId={id} opts={videoOptions} />
            </div>
          ))}
        </AlisaSlider>
      </div>
    </section>
  );
};

export default WorkExemple;
