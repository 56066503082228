import { useState } from 'react';
import CustomSlider from '../Slider/Slider';

import slide1 from '../../image/example/time/1.jpg';
import slide2 from '../../image/example/polotno/4.jpg';
import slide3 from '../../image/example/art/5.jpg';
import slide4 from '../../image/example/coladj/4.jpg';




import './Main.scss';


const Main = ({modal}) => {
  const [mouseOver, setMouseOver] = useState(false);



  const settingSlider = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 1000,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  }

  const changeStyle = (e) => {
    if (e.target.tagName === 'BUTTON') {
      setMouseOver(true);
    } else {
      setMouseOver(false)
    }
    
  }

  return (
    <main className="main">
      <div className="main__container main__container-decktop">
        <div className="main__description">
          <h1 className='main__title'>Картини на полотні -</h1>
          <p className="main__text">незабутній подарунок для близьких та рідних</p>

          <div 
            className="main__order-block order" 
            style={mouseOver ? {'backgroundColor': '#FF7E04'} : {}}
            onMouseOver={(e) => changeStyle(e)}>
            <div className="order__text f-size-20">Виготовлення від 3х годин</div>
            <button className='order__button button f-size-20' onClick={() => modal(true)}>Замовити</button>
          </div>
        </div>

        <div className="main__slider">
          <CustomSlider settings={settingSlider}>
            <img src={slide1} key={1} className='main__slider-image' alt="slide1" />
            <img src={slide2} key={2} className='main__slider-image' loading='lazy' alt="slide1" />
            <img src={slide3} key={3} className='main__slider-image' loading='lazy' alt="slide1" />
            <img src={slide4} key={4} className='main__slider-image' loading='lazy' alt="slide1" />
            {/* <img src={slide5} key={5} className='main__slider-image' loading='lazy' alt="slide1" /> */}
          </CustomSlider>
        </div>
      </div>

      <div className="main__container main__container-mobile">
        <div className="main__description">
          <h1 className='main__title'>Картини на полотні -</h1>
          <p className="main__text">незабутній подарунок для близьких та рідних</p>

          <div className="main__slider">
            <CustomSlider settings={settingSlider}>
              <img src={slide1} key={1} className='main__slider-image' alt="slide1" />
              <img src={slide2} key={2} className='main__slider-image' loading='lazy' alt="slide1" />
              <img src={slide3} key={3} className='main__slider-image' loading='lazy' alt="slide1" />
              <img src={slide4} key={4} className='main__slider-image' loading='lazy' alt="slide1" />
              {/* <img src={slide5} key={5} className='main__slider-image' loading='lazy' alt="slide1" /> */}
            </CustomSlider>
          </div>

          <div 
            className="main__order-block order" 
            style={mouseOver ? {'backgroundColor': '#FF7E04'} : {}}
            onMouseOver={(e) => changeStyle(e)}>
            <div className="order__text f-size-20">Виготовлення від 3х годин</div>
            <button className='order__button button f-size-20' onClick={() => modal(true)} >Замовити</button>
          </div>
        </div>

        
      </div>
    </main>
  )
}

export default Main;