
import arrow from '../../image/icons/step_arrow.webp';
import setting from '../../image/icons/setting.webp';
import nature from '../../image/icons/nature.webp';
import orig from '../../image/icons/orig.webp';
import geler from '../../image/icons/geler.webp';
import instal from '../../image/icons/instal.webp';
import delivery from '../../image/icons/delivery-icon.webp';

import './PriceIncluded.scss';

const data = [
  {
    src: setting,
    text: 'Покращення та обробка вашого фото'
  },
  {
    src: nature,
    text: 'Якісне НАТУРАЛЬНЕ полотно'
  },
  {
    src: orig,
    text: 'Оригінальні пігментні чорнила (кольорова передача 1 в 1)'
  },
  {
    src: geler,
    text: 'Галерейна натяжка на підрамник '
  },
  {
    src: instal,
    text: 'Установка кріплення'
  },
  {
    src: delivery,
    text: 'Дешева доставка пошти (пакуємо в свій картон)'
  },
]

const PriceIncluded = () => {

  const renderIncluded = (data) => {
    return data.map((item, i) => {
      const {src, text} = item;
      return (
        <li className='price-included__list-item' key={text}>
          <div className="price-included__list-item-container">
            {i + 1}
            <img src={src} alt={text} />
          </div>
          <p className="price-included__list-item-text">
            {text}
          </p>
        </li>
      )
    })
  }

  return (
    <section className='price-included reveal'>
      <div className="price-included__header">
        <h2 className='price-included__title'>У ціну входить:</h2>
        <img src={arrow} className='price-included__icon' alt="arrow" />
      </div>

      <ul className="price-included__list">
        {renderIncluded(data)}
      </ul>
    </section>
  )
}

export default PriceIncluded;