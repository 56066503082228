import olena from '../../image/reviews/1.webp'
import nadia from '../../image/reviews/2.webp'
import vika from '../../image/reviews/3.webp'
import CustomSlider from '../Slider/Slider'

import './Reviews.scss'

const data = [
  {
    src: olena,
    name: 'Олена',
    text: '«Доброго дня! Хотіла подякувати ща мега швидку доставку та за подарунок до картини. Картина перевершила всі очікування, вживу ще красивіша, ніж на фото. Ваш художник постарався і виконав всі забаганки) Замовлятиму ще🤍»',
    classes: 'reviews__black'
  },
  {
    src: nadia,
    name: 'Надія',
    text: '«Доброго ранку))) Дякуємо вам за картину))) Вона нереально красива та якісна, у донечки емоції просто зашкалюють😍',
    classes: 'reviews__white'
  },
  {
    src: vika,
    name: 'Вікторія',
    text: ' «Добрий день! Картину отримали, якість просто супер🔥 Зробили дуууже швидко і красиво, дякую велике)»',
    classes: 'reviews__black'
  }
]

const Reviews = ({ modal }) => {
  const renderReview = (data) => {
    return data.map((item) => {
      const { src, name, text, classes } = item

      return (
        <li className={`reviews__item ${classes}`} key={name}>
          <img src={src} className='reviews__picture' alt={name} />

          <div className='reviews__info'>
            <h3 className='reviews__name'>{name}</h3>
            <p className='reviews__review'>{text}</p>
            <button
              className='reviews__ordered button'
              onClick={() => modal(true)}
            >
              Замовити
            </button>
          </div>
        </li>
      )
    })
  }

  return (
    <section className='reviews reveal'>
      <div className='reviews__container'>
        <h2 className='reviews__title title'>відгуки</h2>

        <ul className='reviews__list'>
          <CustomSlider
            settings={{
              arrows: false,
              dots: true,
              infinite: true,
              speed: 1000,
              lazyLoad: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: true,
              autoplaySpeed: 5000,
             


            }}
          >
            {renderReview(data)}
          </CustomSlider>
        </ul>

        <ul className='reviews__list'>

        </ul>
      </div>
    </section>
  )
}

export default Reviews
