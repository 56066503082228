
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import validationForm from '../../utils/validation';
import SendFormService from '../../service/SendFormService';

import big from '../../image/utp/utp.webp';
import arrow from '../../image/icons/arrow-white.webp';
import gift from '../../image/icons/gift.webp';

import './UniqueProposition.scss';


const UniqueProposition = () => {
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const navigate = useNavigate();

  const {sendForm} = SendFormService()

  useEffect(() => {
    // console.log(name, phone);
    // console.log(validationForm(name, 'name').errorStatus, validationForm(phone, 'number').errorStatus);
    if (!validationForm(name, 'name').errorStatus && name !== '' && phone !== '' && !validationForm(phone, 'number').errorStatus) {
      setDisabled(false);
      
    } else {
      setDisabled(true);
    }

  }, [name, phone])

  // [validationForm(name, 'name').errorStatus, validationForm(phone, 'number').errorStatus]
  const formSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name, phone, 
    };

      sendForm(data)
      .then(() => navigate('/thanks'))
      .catch((e) => console.error(e))
      .finally(() => {
        setName('');
        setPhone('');

      })

  }

  return (
    <section className='unique reveal'>
      <div className="unique__container">

        <div className="unique__container-utp utp">
          <h3 className='utp__title'>Унікальна <span>пропозиція</span></h3>
          <img src={arrow} className='utp__arrow' alt="arrow icon" />

          <div className="utp__container">
            <div className="utp__container-num">
              <h3 className='utp__container-num-title'>1+1=3</h3>
              <img className='utp__container-num-img' src={gift} alt="gift" />
              <p className='utp__container-text'>Замов прямо зараз!</p>
            </div>
            
          </div>

          <form className="unique__form form" onSubmit={formSubmit}>

            <label htmlFor="name" className='form__label'>
              <input  
                className='form__input' 
                name='name' id='name' 
                type="text" 
                placeholder='Ім’я'
                value={name}
                onChange={e => setName(e.target.value)}
                />
              
              {validationForm(name, 'name').errorStatus ? <div className="error">Мінімум 2 символи</div> : null}
            </label>
            
            <label htmlFor="phone" className='form__label'>
              <input 
                className='form__input' 
                name='phone' 
                id='phone' 
                type="text" 
                placeholder='+38 (***) *** ****'
                value={phone}
                onChange={e => setPhone(e.target.value)}
                />
              {validationForm(phone, 'number').errorStatus ? <div className="error ">Формат +380999999999</div> : null}
            </label>
            
            <button className='form__button button' disabled={disabled} type='submit'>Відправити</button>
          </form>

        </div>
        <img className="unique__container-img" src={big} alt="Зображення"></img>
      </div>

      
    </section>
  )
}

export default UniqueProposition;